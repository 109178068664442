<template>
    <app-form class="form--forgot-pass">

        <app-input
            class="form__input form__input--username"

            v-model="user.email"

            label="E-mail"
            type="email"
            :icon="'email'"

            :error="errors.fields.email"
            :disabled="disabled"

            @change="showNextError('email')"
        />

        <div class="form__btns">
            <button class="form__button btn" :disabled="disabled" @click.prevent="forgotPass">Reset Password</button>
        </div>
    </app-form>
</template>

<script>
import appInput from '@/components/app-input-sign'
import appForm from '@/components/app-form'

import UserDetailsValidator from '@/validators/user-details-validator'

export default {
    components: {
        appInput,
        appForm,
    },

    props: {
        disabled: { type: Boolean, default: false },
    },

    data() {
        return {
            user: {
                email: '',
            },

            errors: {
                fields: {},
            },

        }
    },

    methods: {
        forgotPass() {
            if (this.validation()) {
                this.$emit('setProcessing', true)

                this.$emit('setError', null)

                // this.$store.dispatch('ForgotPassword', this.user).then(() => {
                //     this.$emit('setProcessing', false)

                //     this.$router.push({ name: 'home' })
                // })
                // .catch(error => {
                //     this.$emit('setError', error)

                //     this.$emit('setProcessing', false)
                // })
            }
        },

        validation() {
            let is_valid = true

            this.errors = { fields: {} }
            this.$emit('setError', null)

            const values = {
                email: {
                    value: this.user.email,
                },
            }

            for (const key in values) {
                if (UserDetailsValidator.isRuleExists(key)) {
                    if (UserDetailsValidator.isInvalid(key, values[key].value, values[key])) {
                        this.errors.fields[ key ] = UserDetailsValidator.getError()

                        is_valid = false
                    }
                }
            }

            return is_valid

            // return !this.showNextError()
        },

        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }
        },
    },
}
</script>

<style lang="scss">
</style>