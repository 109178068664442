<template>
    <sign-layout class="forgot-pass-page" :processing="processing" :error="error">

        <template #default>
            <div class="forgot-pass-page__content">
                <h3 class="forgot-pass-page__title">Forgot pasword?</h3>

                <p class="forgot-pass-page__lead">Please enter the e-mail you use when creating your account, we'll send you instruction to reset your password.</p>

                <forgot-pass-form
                    class="forgot-pass-page__form"
                    :disabled="processing"
                    @setProcessing="setProcessing"
                    @setError="setError"
                />

                <p>
                    <router-link class="forgot-pass-page__alternative" :to="{name: 'sign-in'}">
                        Back to Login
                    </router-link>
                </p>
            </div>
        </template>

    </sign-layout>
</template>

<script>
import SignLayout from '@/layouts/sign.vue'
import ForgotPassForm from './form.vue'

export default {
    components: {
        SignLayout,
        ForgotPassForm,
    },
    data() {
        return {
            processing: false,

            error: null,
        }
    },
    methods: {
        setProcessing(value) {
            this.processing = value
        },

        setError(error = null) {
            this.error = error
        },
    },
}
</script>

<style lang="scss">
</style>
